
import { defineComponent, ref } from "vue";
import Swal from "sweetalert2/dist/sweetalert2.js";
import ApiService from "@/core/services/ApiService";
import moment from "moment";
import Mustache from "mustache";

export default defineComponent({
  name: "print-report-modal",
  components: {
  },
  setup() {
    const formRef = ref<null | HTMLFormElement>(null);
    const reportData = ref<any>({});
    const templates = [
        {label:'Acceptance Letter', value:4},
        {label:'Enrollment Verification(Active)', value:3},
        {label:'Enrollment Verification(Inactive)', value:2},
        {label:'Enrollment Verification(Active) - No Header', value:9},
        {label:'Enrollment Verification(Inactive) - No Header', value:8}
    ];
    const template = ref('');
    const variables = ref([] as any);
    const signatureId = 43;

    const getTemplate = () => {
        ApiService.setHeader();
        ApiService.get("report/"+reportData.value.reportId)
            .then(({data}) => {
                template.value = data.data.report_content;
                variables.value = Mustache.parse(template.value)
                                    .filter(function(v) { return v[0] === 'name' })
                                    .filter(function(v) { return v[1] !== 'signature_url'})
                                    .map(function(v) { return { label : v[1], value: '' };});
                variables.value.filter(function(v) { return v.label == 'print_date'}).map(function(v) {v.value=moment().format('MM/DD/YYYY')})
            })
    }

    const getSignatureUrl = () => {
        return new Promise((resolve, reject) => {
            ApiService.setHeader();
            ApiService.get("file/url/"+signatureId)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    console.log('getSignatureUrl error => '+response.data.errors);
                    reject(new Error ('getSignatureUrl => '+response.data.errors));
                });;
        });
    }

    const rules = ref({  
        type: [
            {
                required: true,
                message: "Please select event type",
                trigger: "blur",
            },
        ],
        title: [
            {
                required: true,
                message: "Please enter the event title",
                trigger: "change",
            },
        ],
    });
    
    const print = () => {
        if (!formRef.value) {
            return;
        }

        getSignatureUrl().then((data : any)=>{
            let variableClass= {} as any;
            variables.value.map(x=>{variableClass[x.label] = x.value;});
            variableClass.signature_url = data.url;

            let printedAcceptanceLetter = Mustache.render(template.value, variableClass);
            let reportWindow = window.open("", "Title", "toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=900,height=700,top="+(screen.height-400)+",left="+(screen.width-840));
            if(reportWindow){
                reportWindow.document.body.innerHTML = printedAcceptanceLetter;
            };
        })
    };

    const resetForm = () =>{
      variables.value = [];
    }

    return {
      formRef,
      moment,
      print,
      reportData,
      rules,
      templates,
      getTemplate,
      variables,
      resetForm
    };
  },
});
